.approver-iteration-approval-container {
  padding: 0 2rem 0 2rem;

  .approver-iteration-approval-header-container {
    font-family: "Book Antiqua", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0.5rem 0;
  }


  .approver-iteration-approval-model-and-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  form {
    width: 100%;
  }

  .approver-iteration-approval-info-container {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .approver-iteration-approval-info-padding {
    padding: 0.7rem;
  }

  .approver-iteration-approval-info-button {
    margin: 0.7em 1em 0 0;
  }
}

