.TDObject-dropdown-container {

  .TDObject-dropdown-accordion-item-body-row {
    display: flex;
    align-items: center;

    .TDObject-dropdown-column-space-between {
      display: flex;
      justify-content: space-between;
    }
  }
  .TDObject-dropdown-accordion-item-column-name {
    padding-right: 1rem;
    font-weight: bold;
  }
}
