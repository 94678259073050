.information-3DObject-container {

  .information-3DObject-form-header-container {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #00539CFF; // EEA47FFF peach
    border-radius: 0.4em;
    color: white;
    width: 100%;
    height: 2.7rem;
    align-items: center;
    margin: 0.5rem 0 0.5rem 0;
  }

  .information-3DObject-column {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left: 1px;
    border-style: groove;
    border-color: gray;
  }

  .information-3DObject-right-column {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px;
    border-right: 1px;
    border-style: groove;
    border-color: gray;
  }

  .information-text-and-help {
    display: flex;
    justify-content: space-between;
  }
}
