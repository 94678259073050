.modeller-creation-container {
    padding: 0 2rem 1rem 2rem;
  
    .modeller-creation-header-container {
      font-family: "Book Antiqua", serif;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 1rem 0;
    }
  
    .modeller-model-form-header-container {
      display: flex;
      justify-content: center;
      padding: 0.4rem 0 0.4rem 0;
      background-color: #00539CFF; // EEA47FFF peach
      border-radius: 0.4em;
      color: white;
      width: 100%;
      height: 2.3rem;
      //align-items: center;
      margin: 0.5rem 0 0.5rem 0;
    }
    .modeller-model-form {
  
      .modeller-form-required-star {
        color: red;
      }
  
      .modeller-model-structure-input-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
  
      .modeller-model-form-column {
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 1px;
        border-style: groove;
        border-color: gray;
      }
  
      .modeller-model-form-right-column {
        border-top: 0;
        border-bottom: 0;
        border-left: 1px;
        border-right: 1px;
        border-style: groove;
        border-color: gray;
      }
  
      .modeller-model-reset-button {
        display: flex;
        flex-direction: row-reverse;
      }
  
      .modeller-model-column-files-picker {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
  
      .modeller-model-structure-button {
        white-space: nowrap;
        text-align: center;
        margin-left: 1em;
      }
  
      .modeller-model-buttons-container {
        display: flex;
        justify-content: space-evenly;
      }
  
      .modeller-model-submit-button {
        margin-left: 1em;
        margin-right: 1em;
      }
  
      .modeller-model-submit-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
  
      .modeller-model-created-tag-row {
        margin: 0.5em 0 0.5em 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  