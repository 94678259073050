.homepage-logged-in-container {
  padding: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;

  .homepage-logged-in-header-container {
    padding: 1em;
    font-family: "Book Antiqua", serif;
  }

  .homepage-logged-in-row-container {
    padding: 2em 1em 2em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
