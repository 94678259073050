.admin-publishable-container {
  padding: 0 2rem 0 2rem;

  .admin-publishable-header-container {
    font-family: "Book Antiqua", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0.5rem 0;
    flex-direction: column;
  }

  .approver-approval-accordion-item-column-name {
    font-weight: bold;
  }
}
