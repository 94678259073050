.approval-process-dropdown-container {

  .approval-process-dropdown-legend-buttons-container {
    display: flex;
    justify-content: space-around;
    //border-top: 0;
    //border-bottom: 0;
    //border-left: 1px;
    //border-right: 1px;
    //border-style: groove;
    //border-color: #ffffff;
  }

  .approval-process-dropdown-legend-names-container {
    display: flex;
    justify-content: space-evenly;
    border-top: 0;
    border-bottom: 0;
    border-left: 1px;
    border-right: 1px;
    border-style: groove;
    border-color: gray;
  }
}
