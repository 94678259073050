.gallery-item {
    width: 100%;
    padding: 5px;
}
  
  .gallery-photo {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Adjust z-index to ensure the button is visible */
}

.image-container {
  position: relative; /* Ensure the remove button is positioned relative to this container */
}
