.structure-table-container {
    .structure-table {
      .action-column {
        width: 280px;
      }
      .structure-table-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  