.navigation-bar {
  background-color: #00539CFF; // EEA47FFF peach
  padding: 1em;

  height: 4.5em;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .nav-button {
    align-self: center;
    margin-right: 0.6em;

    .nav-button-notification-badge {
      margin-left: 0.5rem;
    }
  }
}

.navigation-bar-not-logged-in {
  background-color: #00539c;
  padding: 1em;
  height: 4.5em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
