.TDObject-create-form {
  .TDObject-create-form-texture-row {
    display: flex;

    .TDObject-create-form-texture-row-texture-type {
      width: 50%;
    }
  }
  .modeller-model-form-label-and-help {
    display: flex;
    justify-content: space-between;
  }
}
