.photo-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .photo-item {
    position: relative;
    width: 200px;
    height: 200px;
  }
  
  .uploaded-photo-thumbnail {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 5px;
  }
  
  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  @media (max-width: 767px) {
    .photo-list {
      grid-template-columns: 1fr;
    }
  }
