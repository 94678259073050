.street-table-container {
    .street-table {
      .action-column {
        width: 280px;
      }
      .street-table-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  