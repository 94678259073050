.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;

  &.closing {
    animation: fadeOut 0.3s ease forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .lightbox {
    background-color: white;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.3s ease;
  
    &.slide-out {
      animation: slideOut 0.3s ease forwards;
    }
  
    @keyframes slideIn {
      from {
        transform: translateY(-50px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  
    @keyframes slideOut {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-50px);
        opacity: 0;
      }
    }
    .lightbox-content {
      width: 100%;
      height: 100%;
    }
    
    .lightbox-image {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
      object-fit: contain;
    }
  }
}