.approval-iteration-role-result-container {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .approval-iteration-role-result-row-container-centered {
    padding: 0.1rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    //.approval-iteration-role-result-row-button-container {
    //  display: flex;
    //  justify-content: center;
    //}
  }

  .my-hr {
    border-top: 1px solid black;
    width: 100%;
  }

  .approval-iteration-role-result-row-container {
    padding: 0.1rem;
    width: 100%;
  }
}

