.modeller-model-detail-view-container {
  padding: 0 2rem 0 2rem;

  .modeller-model-detail-view-header-container {
    font-family: "Book Antiqua", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0.5rem 0;
  }

  .modeller-model-detail-view-model-and-buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .modeller-model-detail-view-info-container {
      width: 50%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .modeller-model-detail-view-info-buttons-container {
        display: flex;
        flex-direction: column;

        .btn {
          margin-top: 5px;
        }
      }

      .TDObject-dropdown-actions-column {
        width: 50%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
