.approval-iteration-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 0.4em;
  border: solid #b2b2b2 0.05rem;
  margin-bottom: 0.5rem;

  .approval-iteration-name-header {
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #00539CFF; // EEA47FFF peach
    border-radius: 0.4rem 0.4rem 0 0;
    color: white;
    width: 100%;
    display: flex;
    height: 2.1rem;
  }

  .approval-iteration-name-content {
    font-size: 1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
  }
}
