.TDObject-create-form {
  .TDObject-create-form-texture-row {
    display: flex;

    .TDObject-create-form-texture-row-texture-type {
      width: 100%;
    }
  }
  .modeller-model-form-label-and-help {
    display: flex;
    justify-content: space-between;
  }

  .modeller-model-warning-button {
    flex-direction: row!important;
    color: red;
  }
}
