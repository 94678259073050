.approval-iteration-admin-result-container {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .approval-iteration-admin-result-row-container-centered {
    padding: 0.1rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

  }

  .approval-iteration-admin-button-and-help-container {
    display: flex;
    align-items: center;
  }

  .my-hr {
    border-top: 1px solid black;
    width: 100%;
  }

  .approval-iteration-admin-result-row-container {
    padding: 0.1rem;
    width: 100%;
  }
}

